import api from "../../../utils/api";

  const getDoctorAppointmentCount = async (data) => {
    const response = await api.get(`/appointment/getDoctorAppointmentCount`, data);
    return response.data;
  };

  const doctorDashboard = {
    getDoctorAppointmentCount,
  };
  
  export default doctorDashboard;