import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userRoleService from "./user.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  userData: null,
  user: null,
  getAllUser: [],
  deleteUser: null,
  getUserData: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createUser = createAsyncThunk(
  "user/create",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await userRoleService.createUser(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      } else {
        Notification(response.message, false);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserList = createAsyncThunk(
  "user/getList",
  async ({ finalData }, thunkAPI) => {
    try {
      const response = await userRoleService.getAllUser(finalData);
      if (response.succeeded === true) {
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUser = createAsyncThunk(
  "user/getUser",
  async (finalData, thunkAPI) => {
    try {
      return await userRoleService.getUser(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification("message", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await userRoleService.updateUser(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response.data);
        }
        // callBackFunc();
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ finalData, onDeleteSuccess }, thunkAPI) => {
    try {
      const response = await userRoleService.deleteUser(finalData);
      if (response.succeeded === true) {
        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userRoleSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createUserRole = action.payload;
        // let a = state.getAllUser;
        //     a.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getUserData = action.payload.data;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getUserList.pending, (state) => {
        state.isLoading = true;
        state.getAllUser = [];
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllUser = action.payload;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteUser = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userData = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = userRoleSlice.actions;

export default userRoleSlice.reducer;
