import api from "../../../utils/api";

const getPaymentDetails = async (data) => {
  const response = await api.post(`/strip/getAllPayments`, data);
  return response.data;
};

const getOnlineFormInvoices = async (data) => {
  const response = await api.get(`/form/getAllFormInvoice`, {
    params: data,
  });
  return response.data;
};

const PaymentDetailsService = {
  getPaymentDetails,
  getOnlineFormInvoices
};

export default PaymentDetailsService;
