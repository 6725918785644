import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import doctorSchedule from "./doctorSchedule.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  doctorScheduleData: null,
  allDoctotScheduleData: null,
  createDoctotScheduleData: null,
  updateDoctotScheduleData: null,
  deleteDoctotScheduleData: null,
  signatureData: null,
  allSlots: null,
  remainingAptPresData: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getDoctorSchedule = createAsyncThunk(
  "doctorSchedule/getDoctorSchedule",
  async (finalData, thunkAPI) => {
    try {
      return await doctorSchedule.getDoctorSchedule(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSlots = createAsyncThunk(
  "doctorSchedule/getAllSlots",
  async (finalData, thunkAPI) => {
    try {
      return await doctorSchedule.getAllSlots(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createDoctorSchedule = createAsyncThunk(
  "doctorSchedule/createDoctorSchedule",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await doctorSchedule.createDoctorSchedule(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateDoctorSchedule = createAsyncThunk(
  "doctorSchedule/updateDoctorSchedule",
  async ({ updateFeeData, moveToNext }, thunkAPI) => {
    try {
      const response = await doctorSchedule.updateDoctorSchedule(updateFeeData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllDoctorSchedule = createAsyncThunk(
  "doctorSchedule/getAllDoctorSchedule",
  async (finalData, thunkAPI) => {
    try {
      const response = await doctorSchedule.getAllDoctorSchedule(finalData);
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteDoctorSchedule = createAsyncThunk(
  "doctorSchedule/deleteDoctorSchedule",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await doctorSchedule.deleteDoctorSchedule(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadDoctorSignature = createAsyncThunk(
  "upload/doctorSignature",
  async ({ signatureURL, moveToNext }, thunkAPI) => {
    try {
      const response = await doctorSchedule.doctorSignature(signatureURL);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification("Signature is Created Successfully", response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const remainingAptPres = createAsyncThunk(
  "doctor/remainingAptPres",
  async (finalData, thunkAPI) => {
    try {
      return await doctorSchedule.getRemainingAptPres(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const doctorScheduleSlice = createSlice({
  name: "doctorSchedule",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDoctorSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctorScheduleData = action.payload.data;
      })
      .addCase(getDoctorSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getAllSlots.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllSlots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSlots = action.payload.data;
      })
      .addCase(getAllSlots.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(createDoctorSchedule.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createDoctorSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createDoctotScheduleData = action.payload;
      })
      .addCase(createDoctorSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateDoctorSchedule.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateDoctorSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateDoctotScheduleData = action.payload;
      })
      .addCase(updateDoctorSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllDoctorSchedule.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllDoctorSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allDoctotScheduleData = action.payload;
      })
      .addCase(getAllDoctorSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteDoctorSchedule.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deleteDoctorSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteDoctotScheduleData = action.payload;
      })
      .addCase(deleteDoctorSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(uploadDoctorSignature.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(uploadDoctorSignature.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.signatureData = action.payload;
      })
      .addCase(uploadDoctorSignature.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(remainingAptPres.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(remainingAptPres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.remainingAptPresData = action.payload;
      })
      .addCase(remainingAptPres.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = doctorScheduleSlice.actions;

export default doctorScheduleSlice.reducer;
