import api from "../../../utils/api";

const getVonageVideoCallToken = async (data) => {
    const response = await api.get(`/vonage/getToken`, {
      params: data,
    });
    return response?.data;
  };

  const vonageVideoCall = {
    getVonageVideoCallToken,
  };
  
  export default vonageVideoCall;