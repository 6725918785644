import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import subscriptionPlansService from "./subscriptionPlans.service";

const initialState = {
  subscriptionPlans: null,
  subscriptionPlansList: [],
  subscriptionPlansCount: [],
  patientSubscriptionSave: null,
  getAllPatientPlanList: [],

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createPlan = createAsyncThunk(
  "subscriptionPlans/create",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await subscriptionPlansService.createPlans(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSubscriptionPlans = createAsyncThunk(
  "subscriptionPlans/getAll",
  async (thunkAPI) => {
    try {
      return await subscriptionPlansService.getAllPlans();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      Notification("Network Error", false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllSubscriptionPlansCount = createAsyncThunk(
  "subscriptionPlansCount/getAll",
  async (_, thunkAPI) => {
    try {
      return await subscriptionPlansService.getAllPlansCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const allPatientPlanData = createAsyncThunk(
  "patientPlan/getAll",
  async (finalData, thunkAPI) => {
    try {
      return await subscriptionPlansService.getAllPatientPlan(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientSubscriptionSaveData = createAsyncThunk(
  "subscriptionSave/patient",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await subscriptionPlansService.patientSubscriptionSave(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const subscriptionPlansSlice = createSlice({
  name: "subscriptionPlans",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createPlan.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptionPlans = action.payload.data;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllSubscriptionPlans.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllSubscriptionPlans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptionPlansList = action.payload.data;
      })
      .addCase(getAllSubscriptionPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllSubscriptionPlansCount.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllSubscriptionPlansCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscriptionPlansCount = action.payload.data;
      })
      .addCase(getAllSubscriptionPlansCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(allPatientPlanData.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(allPatientPlanData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientPlanList = action.payload.data;
      })
      .addCase(allPatientPlanData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(patientSubscriptionSaveData.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(patientSubscriptionSaveData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.patientSubscriptionSave = action.payload.data;
      })
      .addCase(patientSubscriptionSaveData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = subscriptionPlansSlice.actions;

export default subscriptionPlansSlice.reducer;
