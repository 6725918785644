import api from "../../../utils/api";

const createPatientHistory = async (data) => {
    const response = await api.post(`/patientHistory/create`, data);
    return response.data;
  };

  const getPatientHistory = async (data) => {
    const response = await api.get(`/patientHistory/get`, {
        params: data,
      });
    return response.data;
  };

  const updatePatientHistory = async (data) => {
    const response = await api.put(`/patientHistory/update`, data);
    return response.data;
  };

  const deletePatientHistory = async (data) => {
    const response = await api.delete("/patientHistory/delete", {
      params: data,
    } );
    return response.data;
  };

  const patientHistory = {
    createPatientHistory,
    getPatientHistory,
    updatePatientHistory,
    deletePatientHistory
  };
  
  export default patientHistory;