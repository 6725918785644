import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import patientRefNotesServices from "./patientRefNotes.service";

const initialState = {
  refSpecialistLetter: null,
  getAllReferralNotes: null,
  refEmergencyLetter: null,
  profileNotesData: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createPatientRef = createAsyncThunk(
  "patient/createRefNotes",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.createPatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRefNotes = createAsyncThunk(
  "patient/getRefNotes",
  async ({ finalData }, thunkAPI) => {
    try {
      return await patientRefNotesServices.getRefNotes(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientRef = createAsyncThunk(
  "patient/deleteRefNotes",
  async ({ finalData, onCreateDeleteSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.deletePatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateDeleteSuccess) {
          onCreateDeleteSuccess();
        }
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePatientRef = createAsyncThunk(
  "patient/updateRefNotes",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.updatePatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Emergency Department Letter
export const createPatientEmerRef = createAsyncThunk(
  "patient/createPatientEmerRef",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.createPatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getEmerRefNotes = createAsyncThunk(
  "patient/getEmerRefNotes",
  async ({ finalData }, thunkAPI) => {
    try {
      return await patientRefNotesServices.getRefNotes(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletePatientEmerRef = createAsyncThunk(
  "patient/deletePatientEmerRef",
  async ({ finalData, onCreateDeleteSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.deletePatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateDeleteSuccess) {
          onCreateDeleteSuccess();
        }
        Notification(response.message, response.succeeded);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePatientEmerRef = createAsyncThunk(
  "patient/updatePatientEmerRef",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.updatePatientRefNotes(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllRefNotesData = createAsyncThunk(
  "patient/getAllRefNotesList",
  async ({ finalData }, thunkAPI) => {
    try {
      const response = await patientRefNotesServices.getAllRefNotes(finalData);
      if (response.succeeded === true) {
        // Notification(response.message, response.succeeded);
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProfileNotes = createAsyncThunk(
  "patient/profileNotes",
  async (finalData, thunkAPI) => {
    try {
      return await patientRefNotesServices.getPatientProfileNotes(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const patientRefNotesSlice = createSlice({
  name: "patientRefNotes",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatientRef.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refSpecialistLetter = action.payload;
      })
      .addCase(createPatientRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getRefNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRefNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getRefNotesData = action.payload.data;
      })
      .addCase(getRefNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientRef.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refSpecialistLetter = action.payload;
      })
      .addCase(deletePatientRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePatientRef.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refSpecialistLetter = action.payload;
      })
      .addCase(updatePatientRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getEmerRefNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getEmerRefNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmerRefNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refEmergencyLetter = action.payload.data;
      })
      .addCase(createPatientEmerRef.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createPatientEmerRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refEmergencyLetter = action.payload.data;
      })
      .addCase(createPatientEmerRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientEmerRef.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatientEmerRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refEmergencyLetter = action.payload;
      })
      .addCase(deletePatientEmerRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePatientEmerRef.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatientEmerRef.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refEmergencyLetter = action.payload;
      })
      .addCase(updatePatientEmerRef.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllRefNotesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRefNotesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllReferralNotes = action.payload?.data;
      })
      .addCase(getAllRefNotesData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getProfileNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileNotesData = action.payload?.data;
      })
      .addCase(getProfileNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientRefNotesSlice.actions;

export default patientRefNotesSlice.reducer;
