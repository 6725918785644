import api from "../../../utils/api";

const getTotalAppointments = async () => {
  const response = await api.get(`/PatientDashBoard/GetTotalAppointments`);
  return response.data;
};

const patientDashboard = {
  getTotalAppointments,
};

export default patientDashboard;
