import api from "../../../utils/api";

const createPlans = async (data) => {
  const response = await api.post(`/plan/savePlan`, data);
  return response.data;
};

const getAllPlans = async (data) => {
  const response = await api.get(`/plan/getAllPlan`, data);
  return response.data;
};

const getAllPlansCount = async (data) => {
  const response = await api.get(`/plan/getAllPlanCount`, data);
  return response.data;
};

const getAllPatientPlan = async (data) => {
  const response = await api.get(`/plan/getAllPatientPlan`, {
    params: data,
  });
  return response.data;
};

const patientSubscriptionSave = async (data) => {
  const response = await api.post(`/plan/patientSubscriptionSave`, data);
  return response.data;
};

const subscriptionPlansService = {
  createPlans,
  getAllPlans,
  getAllPlansCount,
  getAllPatientPlan,
  patientSubscriptionSave
};

export default subscriptionPlansService;
