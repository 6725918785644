import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../../components/GenericActions";
import notification from "./notification.service";

const initialState = {
  notification: null,
  allNotifications: [],
  createdNotifcation: null,
  updatedNotification: null,
  deletedNotification: null,
  unReadNotificationData: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getNotification = createAsyncThunk(
  "notification/getNotification",
  async (thunkAPI) => {
    try {
      return await notification.getNotification();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllNotifications = createAsyncThunk(
  "notification/getAllNotifications",
  async (finalData, thunkAPI) => {
    try {
      return await notification.getAllNotifications(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changeUnReadNotification = createAsyncThunk(
  "notification/changeUnReadStatus",
  async (finalDataChangeUnReadNotification, thunkAPI) => {
    try {
      return await notification.changeUnReadNotificationStatus(
        finalDataChangeUnReadNotification
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createNotifcations = createAsyncThunk(
  "notification/createNotifcations",
  async (finalData, thunkAPI) => {
    try {
      return await notification.createNotifcations(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await notification.updateNotification(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await notification.deleteNotification(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notification = action.payload;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allNotifications = action.payload.data;
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(createNotifcations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNotifcations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createdNotifcation = action.payload;
      })
      .addCase(createNotifcations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedNotification = action.payload;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletedNotification = action.payload;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(changeUnReadNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeUnReadNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unReadNotificationData = action.payload;
      })
      .addCase(changeUnReadNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = notificationSlice.actions;

export default notificationSlice.reducer;
