import api from "../../../utils/api";

const getUnreadMessageCount = async (data) => {
    const response = await api.get(`/Chat/getUnreadMessageCount`, {
        params: data,
      });
    return response.data;
  };

  const getInboxList = async (data) => {
    const response = await api.post(`/Chat/getInboxList`, data);
    return response.data;
  };

  const getInboxId = async (data) => {
    const response = await api.post(`/Chat/getInboxId`, data);
    return response.data;
  };

  const createInbox = async (data) => {
    const response = await api.post(`/Chat/createInbox`, data);
    return response.data;
  };

  const getAllMessage = async (data) => {
    const response = await api.post(`/Chat/getAllMessage`, data);
    return response.data;
  };

  const messageIsRead = async (data) => {
    const response = await api.post(`/Chat/messageIsRead`, data);
    return response.data;
  };

  const sendMessage = async (data) => {
    const response = await api.post(`/Chat/sendMessage`, data);
    return response.data;
  };

  const chat = {
    getUnreadMessageCount,
    getInboxList,
    getInboxId,
    createInbox,
    getAllMessage,
    messageIsRead,
    sendMessage
  };
  
  export default chat;