import api from "../../../utils/api";

const twilioCall = async (data) => {
  const response = await api.get('/vonage/twilioToken', data);
  return response.data;
};

const twilioCallService = {
  twilioCall,
};

export default twilioCallService;
