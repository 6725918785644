import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vonageVideoCall from "./vonageVideoCall.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  videoCallData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getVonageVideoCallToken = createAsyncThunk(
  "vonageVideoCall/getVonageVideoCallToken",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await vonageVideoCall?.getVonageVideoCallToken(
        finalData
      );
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext();
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const vonageVideoCallSlice = createSlice({
  name: "vonageVideoCall",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVonageVideoCallToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVonageVideoCallToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.videoCallData = action.payload.data;
      })
      .addCase(getVonageVideoCallToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = vonageVideoCallSlice.actions;

export default vonageVideoCallSlice.reducer;
