import api from "../../../utils/api";

const createPatientNotes = async (data) => {
    const response = await api.post(`/patientNote/create`, data);
    return response.data;
  };

  const getPatientNotes = async (data) => {
    const response = await api.get(`/patientNote/get`, {
        params: data,
      });
    return response.data;
  };

  const getAllPatientNotes = async (data) => {
    const response = await api.post(`/patientNote/getAll`, data);
    return response.data;
  };

  const updatePatientNotes = async (data) => {
    const response = await api.put(`/patientNote/update`, data);
    return response.data;
  };

  const deletePatientNotes = async (data) => {
    const response = await api.delete("/patientNote/delete", {
      params: data,
    } );
    return response.data;
  };

  const patientNotes = {
    createPatientNotes,
    getPatientNotes,
    getAllPatientNotes,
    updatePatientNotes,
    deletePatientNotes
  };
  
  export default patientNotes;