import api from "../../../utils/api";

const confirmNumberOtp = async (sendOtpCode) => {
  const response = await api.post(`/sendSMS/sendOTP`, sendOtpCode);
  return response.data;
};

const resendNumberOtp = async (resendOtpCode) => {
  const response = await api.get(`/sendSMS/confirmOTP`, {
    params: resendOtpCode,
  });
  return response.data;
};

const phoneVerificationService = {
  confirmNumberOtp,
  resendNumberOtp,
};

export default phoneVerificationService;
