import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adminDashboard from "./adminDashboard.service";

const initialState = {
  adminDashCount: null,
  getAllApt: [],
  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getAdminDashCount = createAsyncThunk(
  "doctorDashboard/getAdminDashboardCount",
  async (_, thunkAPI) => {
    try {
      return await adminDashboard.getAdminDashboardCount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAdminDashboardAptList = createAsyncThunk(
  "adminDash/getAllApt",
  async (finalData, thunkAPI) => {
    try {
      const response = await adminDashboard.getAdminDashboardAllApt(finalData);
      if (response.succeeded === true) {
        return response;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const doctorDashboardSlice = createSlice({
  name: "doctorDashboard",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminDashCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminDashCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adminDashCount = action.payload.data;
      })
      .addCase(getAdminDashCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAdminDashboardAptList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAdminDashboardAptList.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAdminDashboardAptList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllApt = action.payload;
      });
  },
});
export const { clearData } = doctorDashboardSlice.actions;

export default doctorDashboardSlice.reducer;
