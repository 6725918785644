import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appointmentDetailsServices from "./appointmentDetails.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  createAppointmentDetails: [],
  createMedicalCertificate: [],
  aptDetails: null,
  deleteMedCert: null,
  getAptDetails: null,
  mediCertificate: null,
  getMediCertificate: null,
  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createAppointmentDetail = createAsyncThunk(
  "appointment/createAppointmentDetail",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await appointmentDetailsServices.createAppointmentDetail(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAptDetail = createAsyncThunk(
  "appointment/updateAptDetail",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response = await appointmentDetailsServices.updateAppointmentDetail(
        finalData
      );
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createMedCertificate = createAsyncThunk(
  "appointment/createMedicalCertificate",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await appointmentDetailsServices.createMedicalCertificate(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
        Notification(response.message, response.succeeded);
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAptDetail = createAsyncThunk(
  "appointment/getAptDetail",
  async (finalData, thunkAPI) => {
    try {
      return await appointmentDetailsServices.getAppointmentDetail(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateMedCertificate = createAsyncThunk(
  "appointment/updateMedicalCertificate",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await appointmentDetailsServices.updateMedicalCertificate(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess();
        }
        Notification(response.message, response.succeeded);
      }

      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMedicalCertificate = createAsyncThunk(
  "appointment/getMedicalCertificate",
  async (finalData, thunkAPI) => {
    try {
      return await appointmentDetailsServices.getMedicalCertificate(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // Notification(message, false);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteMedCertificate = createAsyncThunk(
  "appointment/deleteMedicalCertificate",
  async ({ finalData, onCreateSuccess }, thunkAPI) => {
    try {
      const response =
        await appointmentDetailsServices.deleteMedicalCertificate(finalData);
      if (response.succeeded === true) {
        if (onCreateSuccess) {
          onCreateSuccess(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const appointmentDetailsSlice = createSlice({
  name: "appointmentDetails",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAppointmentDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createAppointmentDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createAppointmentDetails = action.payload;
      })
      .addCase(createAppointmentDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateAptDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateAptDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.aptDetails = action.payload;
      })
      .addCase(updateAptDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAptDetail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAptDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAptDetails = action.payload;
      })
      .addCase(getAptDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(createMedCertificate.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(createMedCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createMedicalCertificate = action.payload;
      })
      .addCase(createMedCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateMedCertificate.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updateMedCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.mediCertificate = action.payload;
      })
      .addCase(updateMedCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getMedicalCertificate.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getMedicalCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getMediCertificate = action.payload.data;
      })
      .addCase(getMedicalCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteMedCertificate.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deleteMedCertificate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteMedCert = action.payload;
      })
      .addCase(deleteMedCertificate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = appointmentDetailsSlice.actions;

export default appointmentDetailsSlice.reducer;
