import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import patient from "./patient.service";
import { Notification } from "../../../components/GenericActions";

const initialState = {
  getPatientData: null,
  getAllPatientData: null,
  updatePatientData: null,
  deletePatientData: null,
  deleteAccount: null,

  isLoggedIn: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getPatient = createAsyncThunk(
  "patient/getPatient",
  async (finalData, thunkAPI) => {
    try {
      return await patient.getPatient(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPatients = createAsyncThunk(
  "patient/getAllPatients",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patient.getAllPatients(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePatient = createAsyncThunk(
  "patient/updatePatient",
  async ({ finalData, moveToNext, pharmacyData, callBackFunc }, thunkAPI) => {
    try {
      const response = await patient.updatePatient(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext({ pharmacyData }, finalData);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatient = createAsyncThunk(
  "patient/deletePatient",
  async ({ finalData, moveToNext }, thunkAPI) => {
    try {
      const response = await patient.deletePatient(finalData);
      if (response.succeeded === true) {
        if (moveToNext) {
          moveToNext(response);
        }
      }
      Notification(response.message, response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePatientAccount = createAsyncThunk(
  "patient/deleteAccount",
  async ({ finalData, logoutAccount }, thunkAPI) => {
    try {
      const response = await patient.deletePatientAccount(finalData);
      if (response.data.succeeded === true) {
        if (logoutAccount) {
          logoutAccount(response);
        }
      }
      Notification("Account is Deleted Successfully", response.succeeded);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error?.response?.status != 401) {
        Notification(message, false);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPatientData = action.payload.data;
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getAllPatients.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(getAllPatients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAllPatientData = action.payload;
      })
      .addCase(getAllPatients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(updatePatient.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatePatientData = action.payload;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(deletePatient.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePatientData = action.payload;
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePatientAccount.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(deletePatientAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteAccount = action.payload;
      })
      .addCase(deletePatientAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});
export const { clearData } = patientSlice.actions;

export default patientSlice.reducer;
