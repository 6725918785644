import api from "../../../utils/api";

// get all invoices
const getAllInvoice = async (saveData) => {
  const response = await api.post(`/Invoice/getInvoiveList`, saveData);
  return response.data;
};

const invoiceListService = {
  getAllInvoice,
};

export default invoiceListService;
