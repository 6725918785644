import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/auth.slice";
import patientHistoryReducer from "./features/patientHistory/patientHistory.slice";
import patientReducer from "./features/patient/patient.slice";
import patientNotesReducer from "./features/patientNotes/patientNotes.slice";
import userRoleReducer from "./features/userManagement/user.slice";
import doctorScheduleReducer from "./features/doctorSchedule/doctorSchedule.slice";
import appointmentReducer from "./features/appointment/appointment.slice";
import appointmentDetailsReducer from "./features/appointmentDetails/appointmentDetails.slice";
import patientPrescriptionReducer from "./features/patientPrescription/patientPrescription.slice";
import vonageVideoCallReducer from "./features/vonageVideoCall/vonageVideoCall.slice";
import patientRefNotesReducer from "./features/patientRefNotes/patientRefNotes.slice";
import patientDashboardReducer from "./features/patientDashboard/patientDashboard.slice";
import doctorDashboardReducer from "./features/doctorDashboard/doctorDashboard.slice";
import adminDashboardReducer from "./features/adminDashboard/adminDashboard.slice";
import chatReducer from "./features/chat/chat.slice";
import notificationReducer from "./features/notification/notification.slice";
import invoiceListReducer from "./features/invoiceList/invoiceList.slice";
import patientPharmacyReducer from "./features/patientPharmacy/patientPharmacy.slice";
import feeReducer from "./features/fee/fee.slice";
import verifyNumberReducer from "./features/phoneVerification/phoneVerification.slice";
import PaymentDetailsReduccer from "./features/paymentDetails/paymentDetails.slice";
import TwilioCallReduccer from "./features/twilioCall/twilioCall.slice";
import onlinePrescriptionFormReducer from "./features/OnlinePrescriptionForm/OnlinePrescriptionForm.slice";
import patientOnlinePrescReducer from "./features/PatientOnlinePrescription/PatientOnlinePrescription.slice";
import subscriptionPlansReducer from "./features/subscriptionPlans/subscriptionPlans.slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    patientHistory: patientHistoryReducer,
    patient: patientReducer,
    patientNotes: patientNotesReducer,
    userRole: userRoleReducer,
    doctorSchedule: doctorScheduleReducer,
    appointment: appointmentReducer,
    appointmentDetails: appointmentDetailsReducer,
    patientPrescription: patientPrescriptionReducer,
    vonageData: vonageVideoCallReducer,
    patientRefNotes: patientRefNotesReducer,
    patientDashboard: patientDashboardReducer,
    doctorDashboard: doctorDashboardReducer,
    adminDashboard: adminDashboardReducer,
    chat: chatReducer,
    notification: notificationReducer,
    invoiceList: invoiceListReducer,
    patientPharmacy: patientPharmacyReducer,
    fee: feeReducer,
    verifyNumber: verifyNumberReducer,
    PaymentDetails: PaymentDetailsReduccer,
    twilioCall: TwilioCallReduccer,
    onlinePrescriptionForm: onlinePrescriptionFormReducer,
    patientOnlinePresc: patientOnlinePrescReducer,
    subscriptionPlans: subscriptionPlansReducer,
  },
});

export default store;
