import api from "../../../utils/api";

const createAdminPresForm = async (saveData) => {
  const response = await api.post(`/form/save`, saveData);
  return response.data;
};

const updateAdminPresForm = async (data) => {
  const response = await api.post(`/form/QuestionUpdate`, data);
  return response.data;
};

const getAdminPresForm = async (data) => {
  const response = await api.get(`/form/get`, {
    params: data,
  });
  return response.data;
};

const getAllAdminPresForm = async (data) => {
  const response = await api.get(`/form/getAll`, {
    params: data,
  });
  return response.data;
};

const deleteAdminQuestion = async (data) => {
  const response = await api.get(`/form/questionDelete`, data);
  return response.data;
};

const questionAdminStatus = async (data) => {
  const response = await api.get(`/form/questionStatus`, {
    params: data,
  });
  return response.data;
};

const questionAdminRequired = async (data) => {
  const response = await api.get(`/form/questionRequried`, {
    params: data,
  });
  return response.data;
};

const formAdminStatus = async (data) => {
  const response = await api.get(`/form/formStatus`, {
    params: data,
  });
  return response.data;
};

const updatePatientFormStatus = async (data) => {
  const response = await api.post(`/form/updatePatientFormStatus`, data);
  return response.data;
};

const onlinePrescriptionFormServices = {
  createAdminPresForm,
  updateAdminPresForm,
  getAdminPresForm,
  getAllAdminPresForm,
  deleteAdminQuestion,
  questionAdminStatus,
  formAdminStatus,
  updatePatientFormStatus,
  questionAdminRequired
};

export default onlinePrescriptionFormServices;
